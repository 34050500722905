import NavMenu from "../../../../../components/public/others/Navbar";
import NavMenuCM from "../../../../../components/public/others/NavbarCM";
import Footer from "../../../../../components/public/others/Footer";
import TableInfoSacop from "../../../../../components/public/transparency/sacop/singleSacop/padrao/TableInfor";
import { useParams } from "react-router-dom";
import { TipoPortal } from "../../../../../services/variables";

export default function SigleInfo(props) {
  const { search } = useParams();

  return (
    <>
      {/* tipo 4 == Câmara  tipo 3 == prefeitura*/}
      {TipoPortal == 3 ? (
        <>
          <NavMenu />
        </>
      ) : (
        <>
          <NavMenuCM />
        </>
      )}
      <div className="section-title rotes_pages">
        <h6>Início / Licitações e contratos / Detalhes</h6>
      </div>

      <TableInfoSacop id={search} />
      <Footer />
    </>
  );
}
